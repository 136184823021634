import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CollectionCategorySideRender } from './CollectionCategorySideRender.js';
import { fetchCollectionCatalogItemById } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType.js';
import { ProductListWrapper } from '../Widgets2.0/ProductListWrapper/ProductListWrapper';
import { FlattenRowDataType } from 'src/components/VirtualisedList/types';
import { scrollOnPageLoad } from 'src/utils/scrollOnPageLoad.js';
import { CLICKED_ITEM_OBJ } from 'src/utils/constants/index.js';
import { flattenCollectionCategoryItems } from 'src/components/VirtualisedList/utils';

const CollectionCategoryProductWidget = () => {
  const dispatch = useDispatch();
  const prevCollectionIdCategoryId = useRef({});
  const itemsBodyRef = useRef(null);
  const productListWrapperRef = useRef(null);
  const [collectionCategoryActive, setCollectionCategoryActive] = useState({
    collectionId: null,
    categoryId: null,
  });

  useEffect(() => {
    const scrollConfig = scrollOnPageLoad();
    let timeoutValue = undefined;
    if (scrollConfig?.toScroll) {
      try {
        const { y_scroll } = scrollConfig;
        timeoutValue = setTimeout(() => {
          sessionStorage.removeItem(CLICKED_ITEM_OBJ);
          window.scrollTo({ top: y_scroll });
        }, 1100); // arbitrary number to make sure components are properly rendered before scrolling back to previous scroll
      } catch (err) {
        console.log('timeout time error:', err);
      }
    }

    return () => {
      if (timeoutValue) {
        clearTimeout(timeoutValue)
      }
    }
  }, []);

  useLayoutEffect(() => {
    /**
     * setting scrollBehavior to auto as a smooth scroll will trigger all category API calls
     * lying above the intended category leading to UI stuttering when auto scrolling
     */
    document.documentElement.style.scrollBehavior = "auto";
  }, [])

  // This ref keeps track of category that's clicked from left section category list.
  const categoryClickedToBeScrolled = useRef(null);

  const { collections, storeData } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
    storeData: state.storeReducer.store,
  }));

  const { collectionCatalogItems } = useSelector((state: any) => ({
    collectionCatalogItems: state.catalogReducer.collectionCatalogItems,
  }));

  /**
  * function to provide number of skelton to show while products are loading.
  * this is just a random number
  * there is no boundation, you can change this number when required;
  * 9 -> for 3 rows on mobile
  * 8 -> for 4 rows on desktop
  * @returns
  */
  const getSkeletonCount = () => {
    return IS_DESKTOP ? 4 : 8;
  };

  /**
   * flattening data such that it can be easily rendered as a virtualised list in `ProductListWrapper`.
   * actual input output and working can be checked from `flattenCollectionCategoryItems`
   */
  const flattenedData = useMemo(() => {
    let flattenData: FlattenRowDataType[] = [];

    flattenData = flattenCollectionCategoryItems({
      collectionCatalogItems,
      collections,
      skeletonCardsCount: getSkeletonCount(),
      collectionObject: (collection) => ({
        id: collection.id,
        Component: () => (<h2
          data-id={collection.id}
          className="collection-heading"
          id={`collection-${collection.id}`}
        >
          {collection.name}
        </h2>),
        type: "component",
        intersectionCallback: () => {
          setCollectionCategoryActive((prevState) => ({
            ...prevState,
            collectionId: Number(collection?.id),
          }));
        }
      }),
      categoryObject: (collection, category) => ({
        id: `${collection.id}-${category.id}`,
        type: "component",
        Component: () => <h3 className="category-item-header">{category.name}</h3>,
        intersectionCallback() {
          setCollectionCategoryActive((prevState) => ({
            ...prevState,
            categoryId: Number(category?.id),
          }));
          loadMoreData(category, collection)
        },
      }),
      itemObject: (collection, category, productItem) => ({
        id: `${collection.id}-${category.id}-${productItem.id}`,
        type: "product-card",
        data: productItem,
      }),
    });

    return flattenData;
  }, [collections, collectionCatalogItems])

  function addCollectionIdCategoryId(collectionId, categoryId) {
    if (!prevCollectionIdCategoryId.current?.[collectionId]?.length) {
      prevCollectionIdCategoryId.current[collectionId] = [];
      prevCollectionIdCategoryId.current?.[collectionId].push(categoryId);
      return;
    }
    if (
      prevCollectionIdCategoryId.current?.[collectionId].find(
        (catId) => catId !== categoryId
      )
    ) {
      if (!prevCollectionIdCategoryId.current?.[collectionId]?.length) {
        prevCollectionIdCategoryId.current[collectionId] = [];
      }
      prevCollectionIdCategoryId.current?.[collectionId].push(categoryId);
      return;
    }
    return;
  }

  function checkIfSamePayloadApiAlreadyCalled(collectionId, categoryId) {
    if (
      !!prevCollectionIdCategoryId.current?.[collectionId]?.length &&
      prevCollectionIdCategoryId.current?.[collectionId].find(
        (catId) => catId === categoryId
      ) !== undefined
    ) {
      return true;
    }

    return false;
  }

  const loadMoreData = (category, collection) => {
    if (
      storeData?.store_id &&
      !checkIfSamePayloadApiAlreadyCalled(collection.id, category.id)
    ) {
      dispatch(
        fetchCollectionCatalogItemById(
          {
            storeId: storeData.store_id,
            collectionId: collection.id,
            categoryId: category.id,
          }
        )
      );
      addCollectionIdCategoryId(collection.id, category.id);
    }
  };

  const scrollToCategory = (idToScroll) => {
    const indexToScroll = flattenedData?.findIndex(dataElement => dataElement?.id === idToScroll);
    productListWrapperRef.current?.scrollToIndex(indexToScroll)
  }

  const setAccordionId = (id) => {
    setCollectionCategoryActive((prevState) => ({
      ...prevState,
      collectionId: id,
    }));
  };

  const collectionCategoryItemsList = () => {
    return (<ProductListWrapper
      ref={productListWrapperRef}
      renderType='virtualised-grid'
      productList={flattenedData}
      oldCardConfigs={{
        showButton: true,
        noTags: false,
      }}
    />)
};

  return (
    <section
      className={`CollectionCategoryProductWidget1`}
      id="CollectionCategoryProductWidget"
    >
      <div className="CollectionCategoryProductWidget1__body !tw-mb-32">
        {IS_DESKTOP && (
          <div className="left-section">
            <CollectionCategorySideRender
              collectionCategoryString={collectionCategoryActive}
              setAccordionId={(id) => setAccordionId(id)}
              categoryClickedToBeScrolled={categoryClickedToBeScrolled}
              scrollToCategory={scrollToCategory}
            />
          </div>
        )}
        <section ref={itemsBodyRef} className="right-section">
          {collectionCategoryItemsList()}
        </section>
      </div>
    </section>
  );
};

export default CollectionCategoryProductWidget;
