import { CollectionCategoryItemsFuncTypes, FlattenRowDataType } from "./types";

  /**
   * function to convert nested collection category data to flattened format
   * eg.
   * ## original data
   * [
   *    collection: {
   *      category1: [item1, item2, item3],
   *      category2: [item1, item2, item3],
   *    },
   *    collection: {
   *      category1: [item1, item2, item3],
   *      category2: [item1, item2, item3],
   *    },
   * ]
   *
   * ## parsed data
   * note:- item can be a skeleton loader or a proper `FlattenRowDataType` object if loading is complete
   * [
   *  collection_heading, -> (as a component type item in the array)
   *  category_heading, -> (as a component type item in the array)
   *  item1,
   *  item2,
   *  collection_heading2, -> (as a component type item in the array)
   *  category_heading2, -> (as a component type item in the array)
   *  item1,
   *  item2,
   * ]
   */
export function flattenCollectionCategoryItems({
  collections,
  collectionCatalogItems,
  skeletonCardsCount = 3,
  collectionObject,
  categoryObject,
  itemObject,
}: CollectionCategoryItemsFuncTypes) {
  const flattenData: FlattenRowDataType[] = [];
  collections.forEach((collection) => {
    flattenData.push(collectionObject(collection));
    collection.categories?.map((category) => {
      flattenData.push(categoryObject(collection, category));
      if (collectionCatalogItems?.[collection.id]?.[category.id]?.length > 0) {
        collectionCatalogItems?.[collection.id]?.[category.id]?.forEach(
          (productItem) => flattenData.push(itemObject(collection, category, productItem))
        );
      } else {
        Array(skeletonCardsCount).fill(0).map((_, index) => {
          flattenData.push({
            id: `${collection.id}-${category.id}-${index}`,
            type: "product-card-skeleton",
          });
        })
      }
    });
  });
  return flattenData;
}
